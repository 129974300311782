import MainAppBar from "../components/MainAppBar";
import React from "react";
import {
  useCreateGameMutation,
  useGetGamesQuery,
  useImAuthedMutation,
  useMyUsernameMutation,
} from "../generated/graphql";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import GameCard from "../components/GameCard";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

export default function Home() {
  const [imAuthed] = useImAuthedMutation();
  const [myUsername] = useMyUsernameMutation({ errorPolicy: "all" });
  const [createGame] = useCreateGameMutation();
  const navigate = useNavigate();
  const getGames = useGetGamesQuery({ errorPolicy: "all", pollInterval: 5000 });

  const [isAdmin, setIsAdmin] = React.useState(false);

  const [createProps, setCreateProps] = React.useState({ name: "", id: "" });

  React.useEffect(() => {
    (async () => {
      const response = await imAuthed();
      if (response.data && !response.data.imAuthed) navigate("/login");
      const res = await myUsername();
      if (res.data && res.data.myUsername === "admin") setIsAdmin(true);
    })();
  }, [imAuthed, navigate, myUsername]);

  const genCards = () => {
    if (getGames.data) {
      const cards: JSX.Element[] = [];
      const gameData = getGames.data.getGames;
      for (let i = 0; i < gameData.length; i++) {
        cards.push(
          <Grid item xs={12} md={6} key={i}>
            <GameCard
              gameData={gameData[i]}
              key={i.toString + "a"}
              getGames={getGames}
              isAdmin={isAdmin}
            />
          </Grid>
        );
      }
      return cards;
    }
    return [<Box key={0}></Box>];
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MainAppBar />
        <Container>
          <Grid container sx={{ paddingTop: "20px" }} spacing={2}>
            {genCards()}
          </Grid>
          {isAdmin && (
            <Stack direction='row' sx={{ marginY: "20px" }} spacing={2}>
              <FormControl>
                <InputLabel htmlFor='component-outlined'>Name</InputLabel>
                <OutlinedInput
                  id='component-outlined'
                  value={createProps.name}
                  onChange={(elem) =>
                    setCreateProps({
                      ...createProps,
                      name: (elem.target as HTMLInputElement).value,
                    })
                  }
                  label='Name'
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor='instance-id'>Instance ID</InputLabel>
                <OutlinedInput
                  id='instance-id'
                  value={createProps.id}
                  onChange={(elem) =>
                    setCreateProps({ ...createProps, id: (elem.target as HTMLInputElement).value })
                  }
                  label='Instance ID'
                />
              </FormControl>
              <Button
                variant='contained'
                onClick={async () => {
                  const response = await createGame({
                    variables: { name: createProps.name, instanceId: createProps.id },
                  });
                  if (response.data && response.data.createGame) {
                    setCreateProps({ name: "", id: "" });
                    getGames.refetch();
                  }
                }}
              >
                Add Game
              </Button>
            </Stack>
          )}
        </Container>
      </LocalizationProvider>
    </div>
  );
}
