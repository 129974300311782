import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { setAccessToken } from "../accessToken";
import { useImAuthedMutation, useLoginMutation } from "../generated/graphql";
import { useSnackbar } from "notistack";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("username Required"),
  password: Yup.string().min(2, "Password too short!").required("Password Required"),
});

export default function Login() {
  const [openPage, setOpenPage] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [doLogin] = useLoginMutation({ errorPolicy: "all" });
  const [imAuthed] = useImAuthedMutation();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      const response = await imAuthed();
      if (response.data && response.data.imAuthed) navigate("/");
    })();
  }, [imAuthed, navigate]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, actions) => {
      try {
        const response = await doLogin({
          variables: {
            username: values.username,
            password: values.password,
          },
        });
        if (response.errors) {
          enqueueSnackbar(response.errors[0].message, { variant: "error" });
          actions.setSubmitting(false);
        } else {
          if (response && response.data) {
            setAccessToken(response.data.login.accessToken);
          }
          actions.setSubmitting(false);
          enqueueSnackbar("Login successful", { variant: "success" });
          setOpenPage(false);
          navigate("/");
        }
      } catch {
        actions.resetForm();
      }
    },
  });

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
        }}
      >
        <Zoom in={openPage}>
          <Box
            sx={{
              backgroundColor: "primary.main",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container maxWidth='sm'>
              <Grid container>
                <Grid item xs></Grid>
                <Grid item xs={12} md={8}>
                  <Card sx={{ marginBottom: "40px", borderRadius: "15px" }}>
                    <CardContent>
                      <Typography
                        variant='h4'
                        sx={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}
                        gutterBottom
                      >
                        Log in
                      </Typography>
                      <form onSubmit={formik.handleSubmit}>
                        <Grid
                          container
                          spacing={3}
                          sx={{ paddingRight: "10px", paddingLeft: "10px" }}
                        >
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id='username'
                              name='username'
                              label='Username'
                              value={formik.values.username}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched.username && Boolean(formik.errors.username)}
                              helperText={formik.touched.username && formik.errors.username}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id='password'
                              name='password'
                              type='password'
                              label='Password'
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched.password && Boolean(formik.errors.password)}
                              helperText={formik.touched.password && formik.errors.password}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant='contained'
                              type='submit'
                              color='primary'
                              sx={{ width: "100%", marginBottom: "10px" }}
                            >
                              Login
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs></Grid>
              </Grid>
            </Container>
          </Box>
        </Zoom>
      </Box>
    </>
  );
}
