import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
  Stack,
  Backdrop,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import RunPeriodDisplay from "./RunPeriodDisplay";
import AddIcon from "@mui/icons-material/Add";
import { DateTimePicker, LoadingButton } from "@mui/lab";
import { add as dateAdd, compareAsc } from "date-fns";
import {
  Exact,
  GetGamesQuery,
  useCreateRunPeriodMutation,
  useDeleteGameMutation,
  useOverrideScheduleMutation,
  useSetInstanceStateMutation,
} from "../generated/graphql";
import { gameDataType } from "../gameDataType";
import { useSnackbar } from "notistack";
import { QueryResult } from "@apollo/client/react/types/types";

export default function GameCard({
  gameData,
  getGames,
  isAdmin,
}: {
  gameData: gameDataType;
  getGames: QueryResult<GetGamesQuery, Exact<{ [key: string]: never }>>;
  isAdmin: boolean;
}) {
  const [addOpen, setAddOpen] = React.useState(false);
  const [addParams, setAddParams] = React.useState<{ start: Date | null; end: Date | null }>({
    start: new Date(),
    end: null,
  });
  const [followSchedule, setFollowSchedule] = React.useState(!gameData.overrideSchedule);
  const { enqueueSnackbar } = useSnackbar();

  const [overrideSchedule] = useOverrideScheduleMutation();

  const [createRunPeriod] = useCreateRunPeriodMutation();

  const [deleteGame] = useDeleteGameMutation({ variables: { deleteGameId: gameData.id } });

  const [setInstanceState] = useSetInstanceStateMutation();

  const formatInstance = () => {
    const code = gameData.instanceState;
    if (code === 16) return { text: "running", colour: "success.main" };
    if (code === 64) return { text: "stopping", colour: "error.main" };
    if (code === 80) return { text: "stopped", colour: "error.main" };
    if (code === 0) return { text: "pending", colour: "warning.main" };
    return { text: code.toString, colour: "warning.main" };
  };

  const genSchedule = () => {
    const elems: JSX.Element[] = [];
    if (gameData.runPeriods !== undefined && gameData.runPeriods !== null) {
      for (let i = 0; i < gameData.runPeriods.length; i++) {
        elems.push(
          <RunPeriodDisplay
            n={i + 1}
            periodData={gameData.runPeriods[i]}
            getGames={getGames}
            key={i}
          />
        );
      }
    }
    if (elems.length === 0)
      return [
        <Typography
          variant='body1'
          sx={{ color: "#aaa", paddingTop: "20px", paddingBottom: "10px" }}
          align='center'
          key='0'
        >
          Nothing in schedule
        </Typography>,
      ];
    return elems;
  };

  return (
    <Box sx={{ border: "1px solid", borderRadius: "15px", borderColor: "primary.main" }}>
      <Box sx={{ padding: "10px 20px 10px 20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography variant='h4'>{gameData.name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: formatInstance().colour,
                  color: "#fff",
                  padding: "5px 8px 5px 8px",
                }}
              >
                <Typography variant='body2'>{formatInstance().text}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' justifyContent='space-between'>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={followSchedule}
                      onChange={async () => {
                        const response = await overrideSchedule({
                          variables: { gameId: gameData.id, doOverride: followSchedule },
                        });
                        if (response.data && response.data.overrideSchedule) {
                          enqueueSnackbar("Follow schedule set!", { variant: "success" });
                          setFollowSchedule(!followSchedule);
                        } else {
                          enqueueSnackbar("Error setting follow schedule", { variant: "error" });
                        }
                      }}
                    />
                  }
                  label='Follow Schedule'
                />
              </FormGroup>
              {gameData.instanceState === 16 && (
                <Button
                  color='error'
                  variant='contained'
                  size='small'
                  sx={{ height: "min-content" }}
                  disabled={followSchedule}
                  onClick={async () => {
                    const response = await setInstanceState({
                      variables: { instanceId: gameData.instanceId, start: false },
                    });
                    if (response.data && response.data.setInstanceState) {
                      enqueueSnackbar(
                        "Success changing state, please wait for approximately 1 minute.",
                        { variant: "success" }
                      );
                      getGames.refetch();
                    } else {
                      enqueueSnackbar("Error changing state, please wait for 1 minute.", {
                        variant: "success",
                      });
                    }
                  }}
                >
                  Stop
                </Button>
              )}
              {gameData.instanceState === 80 && (
                <Button
                  color='success'
                  variant='contained'
                  size='small'
                  sx={{ height: "min-content" }}
                  disabled={followSchedule}
                  onClick={async () => {
                    const response = await setInstanceState({
                      variables: { instanceId: gameData.instanceId, start: true },
                    });
                    if (response.data && response.data.setInstanceState) {
                      enqueueSnackbar(
                        "Success changing state, please wait for approximately 1 minute.",
                        { variant: "success" }
                      );
                      getGames.refetch();
                    } else {
                      enqueueSnackbar("Error changing state, please wait for 1 minute.", {
                        variant: "success",
                      });
                    }
                  }}
                >
                  Start
                </Button>
              )}
              {gameData.instanceState !== 16 && gameData.instanceState !== 80 && (
                <LoadingButton
                  loading
                  variant='contained'
                  size='small'
                  sx={{ height: "min-content" }}
                >
                  Start
                </LoadingButton>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Accordion sx={{ boxShadow: 3 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                sx={{ borderBottom: "1px solid #ccc" }}
              >
                <Typography>
                  Schedule ({gameData.runPeriods ? gameData.runPeriods.length : 0})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction='column'>{genSchedule()}</Stack>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant='outlined'
                    startIcon={<AddIcon />}
                    size='small'
                    sx={{ marginTop: "5px" }}
                    onClick={() => setAddOpen(true)}
                  >
                    Add
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Typography
          variant='body2'
          align='right'
          sx={{ color: "#ccc", paddingRight: "5px", paddingTop: "10px" }}
        >
          {gameData.instanceId}
        </Typography>
        {isAdmin && (
          <Button
            variant='contained'
            onClick={async () => {
              await deleteGame();
              getGames.refetch();
            }}
          >
            Delete Game
          </Button>
        )}
      </Box>
      <Backdrop
        open={addOpen}
        onClick={(event) => {
          if ((event.target as HTMLDivElement).classList.contains("backdropToClose"))
            setAddOpen(false);
        }}
        className='backdropToClose'
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "15px",
            boxShadow: 3,
            padding: "10px 20px 10px 20px",
          }}
        >
          <Stack sx={{ paddingTop: "5px" }}>
            <Typography variant='body2' sx={{ paddingLeft: "2px" }}>
              {gameData.name}
            </Typography>
            <Typography variant='h4'>Schedule Server Start/Stop</Typography>
            <Stack
              direction='column'
              sx={{ marginY: "20px", display: "block" }}
              spacing={3}
              justifyContent='center'
            >
              <DateTimePicker
                renderInput={(props) => <TextField {...props} sx={{ display: "block" }} />}
                label='Start Time'
                inputFormat='dd/MM/yyyy hh:mm aaa'
                value={addParams.start}
                onChange={(newValue) => {
                  if (newValue !== null) setAddParams({ ...addParams, start: newValue });
                  if (
                    addParams.end !== null &&
                    addParams.start !== null &&
                    compareAsc(addParams.end, addParams.start) === -1
                  )
                    addParams.end = dateAdd(addParams.start, { minutes: 1 });
                }}
                maxDateTime={addParams.end}
              />
              <DateTimePicker
                renderInput={(props) => <TextField {...props} sx={{ display: "block" }} />}
                label='End Time'
                inputFormat='dd/MM/yyyy hh:mm aaa'
                value={addParams.end}
                onChange={(newValue) => {
                  if (newValue !== null) setAddParams({ ...addParams, end: newValue });
                  if (
                    addParams.end !== null &&
                    addParams.start !== null &&
                    compareAsc(addParams.end, addParams.start) === -1
                  )
                    addParams.start = dateAdd(addParams.end, { minutes: -1 });
                }}
                minDateTime={addParams.start}
              />
              <Button
                variant='contained'
                sx={{ width: "min-content" }}
                onClick={async () => {
                  if (addParams.end === null || addParams.start === null) {
                    enqueueSnackbar("Please fill in the start and end times", { variant: "error" });
                  } else {
                    if (compareAsc(addParams.end, addParams.start) === 1) {
                      const response = await createRunPeriod({
                        variables: {
                          gameId: gameData.id,
                          startTime: addParams.start.getTime().toString(),
                          endTime: addParams.end.getTime().toString(),
                        },
                      });
                      if (response.data && response.data.createRunPeriod) {
                        enqueueSnackbar("Added to schedule!", { variant: "success" });
                        getGames.refetch();
                        setAddOpen(false);
                      } else {
                        enqueueSnackbar("Error adding to schedule", { variant: "error" });
                      }
                    } else {
                      enqueueSnackbar("Ending time has to be after starting time!", {
                        variant: "error",
                      });
                    }
                  }
                }}
              >
                Schedule
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
}
