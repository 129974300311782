import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Game = {
  __typename?: 'Game';
  id: Scalars['Int'];
  instanceId: Scalars['String'];
  instanceState: Scalars['Float'];
  name: Scalars['String'];
  overrideSchedule: Scalars['Boolean'];
  runPeriods?: Maybe<Array<RunPeriod>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  createGame: Scalars['Boolean'];
  createRunPeriod: Scalars['Boolean'];
  deleteGame: Scalars['Boolean'];
  deleteRunPeriod: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  editRunPeriod: Scalars['Boolean'];
  editUser: Scalars['Boolean'];
  imAuthed: Scalars['Boolean'];
  login: LoginResponse;
  logout: Scalars['Boolean'];
  myUsername: Scalars['String'];
  overrideSchedule: Scalars['Boolean'];
  register: Scalars['Boolean'];
  revokeRefreshTokensForUser: Scalars['Boolean'];
  setInstanceState: Scalars['Boolean'];
};


export type MutationCreateGameArgs = {
  instanceId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateRunPeriodArgs = {
  endTime: Scalars['String'];
  gameId: Scalars['Int'];
  startTime: Scalars['String'];
};


export type MutationDeleteGameArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRunPeriodArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationEditRunPeriodArgs = {
  endTime?: InputMaybe<Scalars['String']>;
  rpId: Scalars['Int'];
  startTime?: InputMaybe<Scalars['String']>;
};


export type MutationEditUserArgs = {
  id: Scalars['Int'];
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationOverrideScheduleArgs = {
  doOverride: Scalars['Boolean'];
  gameId: Scalars['Int'];
};


export type MutationRegisterArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRevokeRefreshTokensForUserArgs = {
  userId: Scalars['Int'];
};


export type MutationSetInstanceStateArgs = {
  instanceId: Scalars['String'];
  start: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  getGames: Array<Game>;
  users: Array<User>;
};

export type RunPeriod = {
  __typename?: 'RunPeriod';
  end: Scalars['String'];
  game: Game;
  id: Scalars['Int'];
  start: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  username: Scalars['String'];
};

export type CreateGameMutationVariables = Exact<{
  instanceId: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateGameMutation = { __typename?: 'Mutation', createGame: boolean };

export type CreateRunPeriodMutationVariables = Exact<{
  endTime: Scalars['String'];
  startTime: Scalars['String'];
  gameId: Scalars['Int'];
}>;


export type CreateRunPeriodMutation = { __typename?: 'Mutation', createRunPeriod: boolean };

export type DeleteGameMutationVariables = Exact<{
  deleteGameId: Scalars['Int'];
}>;


export type DeleteGameMutation = { __typename?: 'Mutation', deleteGame: boolean };

export type DeleteRunPeriodMutationVariables = Exact<{
  deleteRunPeriodId: Scalars['Int'];
}>;


export type DeleteRunPeriodMutation = { __typename?: 'Mutation', deleteRunPeriod: boolean };

export type EditRunPeriodMutationVariables = Exact<{
  rpId: Scalars['Int'];
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
}>;


export type EditRunPeriodMutation = { __typename?: 'Mutation', editRunPeriod: boolean };

export type GetGamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGamesQuery = { __typename?: 'Query', getGames: Array<{ __typename?: 'Game', id: number, name: string, instanceId: string, overrideSchedule: boolean, instanceState: number, runPeriods?: Array<{ __typename?: 'RunPeriod', id: number, start: string, end: string }> | null | undefined }> };

export type ImAuthedMutationVariables = Exact<{ [key: string]: never; }>;


export type ImAuthedMutation = { __typename?: 'Mutation', imAuthed: boolean };

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'User', id: number, username: string } } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type MyUsernameMutationVariables = Exact<{ [key: string]: never; }>;


export type MyUsernameMutation = { __typename?: 'Mutation', myUsername: string };

export type OverrideScheduleMutationVariables = Exact<{
  gameId: Scalars['Int'];
  doOverride: Scalars['Boolean'];
}>;


export type OverrideScheduleMutation = { __typename?: 'Mutation', overrideSchedule: boolean };

export type SetInstanceStateMutationVariables = Exact<{
  start: Scalars['Boolean'];
  instanceId: Scalars['String'];
}>;


export type SetInstanceStateMutation = { __typename?: 'Mutation', setInstanceState: boolean };


export const CreateGameDocument = gql`
    mutation CreateGame($instanceId: String!, $name: String!) {
  createGame(instanceId: $instanceId, name: $name)
}
    `;
export type CreateGameMutationFn = Apollo.MutationFunction<CreateGameMutation, CreateGameMutationVariables>;

/**
 * __useCreateGameMutation__
 *
 * To run a mutation, you first call `useCreateGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameMutation, { data, loading, error }] = useCreateGameMutation({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateGameMutation(baseOptions?: Apollo.MutationHookOptions<CreateGameMutation, CreateGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGameMutation, CreateGameMutationVariables>(CreateGameDocument, options);
      }
export type CreateGameMutationHookResult = ReturnType<typeof useCreateGameMutation>;
export type CreateGameMutationResult = Apollo.MutationResult<CreateGameMutation>;
export type CreateGameMutationOptions = Apollo.BaseMutationOptions<CreateGameMutation, CreateGameMutationVariables>;
export const CreateRunPeriodDocument = gql`
    mutation CreateRunPeriod($endTime: String!, $startTime: String!, $gameId: Int!) {
  createRunPeriod(endTime: $endTime, startTime: $startTime, gameId: $gameId)
}
    `;
export type CreateRunPeriodMutationFn = Apollo.MutationFunction<CreateRunPeriodMutation, CreateRunPeriodMutationVariables>;

/**
 * __useCreateRunPeriodMutation__
 *
 * To run a mutation, you first call `useCreateRunPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunPeriodMutation, { data, loading, error }] = useCreateRunPeriodMutation({
 *   variables: {
 *      endTime: // value for 'endTime'
 *      startTime: // value for 'startTime'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useCreateRunPeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateRunPeriodMutation, CreateRunPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRunPeriodMutation, CreateRunPeriodMutationVariables>(CreateRunPeriodDocument, options);
      }
export type CreateRunPeriodMutationHookResult = ReturnType<typeof useCreateRunPeriodMutation>;
export type CreateRunPeriodMutationResult = Apollo.MutationResult<CreateRunPeriodMutation>;
export type CreateRunPeriodMutationOptions = Apollo.BaseMutationOptions<CreateRunPeriodMutation, CreateRunPeriodMutationVariables>;
export const DeleteGameDocument = gql`
    mutation DeleteGame($deleteGameId: Int!) {
  deleteGame(id: $deleteGameId)
}
    `;
export type DeleteGameMutationFn = Apollo.MutationFunction<DeleteGameMutation, DeleteGameMutationVariables>;

/**
 * __useDeleteGameMutation__
 *
 * To run a mutation, you first call `useDeleteGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameMutation, { data, loading, error }] = useDeleteGameMutation({
 *   variables: {
 *      deleteGameId: // value for 'deleteGameId'
 *   },
 * });
 */
export function useDeleteGameMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGameMutation, DeleteGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGameMutation, DeleteGameMutationVariables>(DeleteGameDocument, options);
      }
export type DeleteGameMutationHookResult = ReturnType<typeof useDeleteGameMutation>;
export type DeleteGameMutationResult = Apollo.MutationResult<DeleteGameMutation>;
export type DeleteGameMutationOptions = Apollo.BaseMutationOptions<DeleteGameMutation, DeleteGameMutationVariables>;
export const DeleteRunPeriodDocument = gql`
    mutation DeleteRunPeriod($deleteRunPeriodId: Int!) {
  deleteRunPeriod(id: $deleteRunPeriodId)
}
    `;
export type DeleteRunPeriodMutationFn = Apollo.MutationFunction<DeleteRunPeriodMutation, DeleteRunPeriodMutationVariables>;

/**
 * __useDeleteRunPeriodMutation__
 *
 * To run a mutation, you first call `useDeleteRunPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunPeriodMutation, { data, loading, error }] = useDeleteRunPeriodMutation({
 *   variables: {
 *      deleteRunPeriodId: // value for 'deleteRunPeriodId'
 *   },
 * });
 */
export function useDeleteRunPeriodMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRunPeriodMutation, DeleteRunPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRunPeriodMutation, DeleteRunPeriodMutationVariables>(DeleteRunPeriodDocument, options);
      }
export type DeleteRunPeriodMutationHookResult = ReturnType<typeof useDeleteRunPeriodMutation>;
export type DeleteRunPeriodMutationResult = Apollo.MutationResult<DeleteRunPeriodMutation>;
export type DeleteRunPeriodMutationOptions = Apollo.BaseMutationOptions<DeleteRunPeriodMutation, DeleteRunPeriodMutationVariables>;
export const EditRunPeriodDocument = gql`
    mutation EditRunPeriod($rpId: Int!, $endTime: String, $startTime: String) {
  editRunPeriod(rpId: $rpId, endTime: $endTime, startTime: $startTime)
}
    `;
export type EditRunPeriodMutationFn = Apollo.MutationFunction<EditRunPeriodMutation, EditRunPeriodMutationVariables>;

/**
 * __useEditRunPeriodMutation__
 *
 * To run a mutation, you first call `useEditRunPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRunPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRunPeriodMutation, { data, loading, error }] = useEditRunPeriodMutation({
 *   variables: {
 *      rpId: // value for 'rpId'
 *      endTime: // value for 'endTime'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useEditRunPeriodMutation(baseOptions?: Apollo.MutationHookOptions<EditRunPeriodMutation, EditRunPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRunPeriodMutation, EditRunPeriodMutationVariables>(EditRunPeriodDocument, options);
      }
export type EditRunPeriodMutationHookResult = ReturnType<typeof useEditRunPeriodMutation>;
export type EditRunPeriodMutationResult = Apollo.MutationResult<EditRunPeriodMutation>;
export type EditRunPeriodMutationOptions = Apollo.BaseMutationOptions<EditRunPeriodMutation, EditRunPeriodMutationVariables>;
export const GetGamesDocument = gql`
    query GetGames {
  getGames {
    id
    name
    instanceId
    overrideSchedule
    instanceState
    runPeriods {
      id
      start
      end
    }
  }
}
    `;

/**
 * __useGetGamesQuery__
 *
 * To run a query within a React component, call `useGetGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGamesQuery(baseOptions?: Apollo.QueryHookOptions<GetGamesQuery, GetGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGamesQuery, GetGamesQueryVariables>(GetGamesDocument, options);
      }
export function useGetGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGamesQuery, GetGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGamesQuery, GetGamesQueryVariables>(GetGamesDocument, options);
        }
export type GetGamesQueryHookResult = ReturnType<typeof useGetGamesQuery>;
export type GetGamesLazyQueryHookResult = ReturnType<typeof useGetGamesLazyQuery>;
export type GetGamesQueryResult = Apollo.QueryResult<GetGamesQuery, GetGamesQueryVariables>;
export const ImAuthedDocument = gql`
    mutation ImAuthed {
  imAuthed
}
    `;
export type ImAuthedMutationFn = Apollo.MutationFunction<ImAuthedMutation, ImAuthedMutationVariables>;

/**
 * __useImAuthedMutation__
 *
 * To run a mutation, you first call `useImAuthedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImAuthedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imAuthedMutation, { data, loading, error }] = useImAuthedMutation({
 *   variables: {
 *   },
 * });
 */
export function useImAuthedMutation(baseOptions?: Apollo.MutationHookOptions<ImAuthedMutation, ImAuthedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImAuthedMutation, ImAuthedMutationVariables>(ImAuthedDocument, options);
      }
export type ImAuthedMutationHookResult = ReturnType<typeof useImAuthedMutation>;
export type ImAuthedMutationResult = Apollo.MutationResult<ImAuthedMutation>;
export type ImAuthedMutationOptions = Apollo.BaseMutationOptions<ImAuthedMutation, ImAuthedMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    accessToken
    user {
      id
      username
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MyUsernameDocument = gql`
    mutation MyUsername {
  myUsername
}
    `;
export type MyUsernameMutationFn = Apollo.MutationFunction<MyUsernameMutation, MyUsernameMutationVariables>;

/**
 * __useMyUsernameMutation__
 *
 * To run a mutation, you first call `useMyUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myUsernameMutation, { data, loading, error }] = useMyUsernameMutation({
 *   variables: {
 *   },
 * });
 */
export function useMyUsernameMutation(baseOptions?: Apollo.MutationHookOptions<MyUsernameMutation, MyUsernameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MyUsernameMutation, MyUsernameMutationVariables>(MyUsernameDocument, options);
      }
export type MyUsernameMutationHookResult = ReturnType<typeof useMyUsernameMutation>;
export type MyUsernameMutationResult = Apollo.MutationResult<MyUsernameMutation>;
export type MyUsernameMutationOptions = Apollo.BaseMutationOptions<MyUsernameMutation, MyUsernameMutationVariables>;
export const OverrideScheduleDocument = gql`
    mutation OverrideSchedule($gameId: Int!, $doOverride: Boolean!) {
  overrideSchedule(gameId: $gameId, doOverride: $doOverride)
}
    `;
export type OverrideScheduleMutationFn = Apollo.MutationFunction<OverrideScheduleMutation, OverrideScheduleMutationVariables>;

/**
 * __useOverrideScheduleMutation__
 *
 * To run a mutation, you first call `useOverrideScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverrideScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overrideScheduleMutation, { data, loading, error }] = useOverrideScheduleMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      doOverride: // value for 'doOverride'
 *   },
 * });
 */
export function useOverrideScheduleMutation(baseOptions?: Apollo.MutationHookOptions<OverrideScheduleMutation, OverrideScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OverrideScheduleMutation, OverrideScheduleMutationVariables>(OverrideScheduleDocument, options);
      }
export type OverrideScheduleMutationHookResult = ReturnType<typeof useOverrideScheduleMutation>;
export type OverrideScheduleMutationResult = Apollo.MutationResult<OverrideScheduleMutation>;
export type OverrideScheduleMutationOptions = Apollo.BaseMutationOptions<OverrideScheduleMutation, OverrideScheduleMutationVariables>;
export const SetInstanceStateDocument = gql`
    mutation SetInstanceState($start: Boolean!, $instanceId: String!) {
  setInstanceState(start: $start, instanceId: $instanceId)
}
    `;
export type SetInstanceStateMutationFn = Apollo.MutationFunction<SetInstanceStateMutation, SetInstanceStateMutationVariables>;

/**
 * __useSetInstanceStateMutation__
 *
 * To run a mutation, you first call `useSetInstanceStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInstanceStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInstanceStateMutation, { data, loading, error }] = useSetInstanceStateMutation({
 *   variables: {
 *      start: // value for 'start'
 *      instanceId: // value for 'instanceId'
 *   },
 * });
 */
export function useSetInstanceStateMutation(baseOptions?: Apollo.MutationHookOptions<SetInstanceStateMutation, SetInstanceStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetInstanceStateMutation, SetInstanceStateMutationVariables>(SetInstanceStateDocument, options);
      }
export type SetInstanceStateMutationHookResult = ReturnType<typeof useSetInstanceStateMutation>;
export type SetInstanceStateMutationResult = Apollo.MutationResult<SetInstanceStateMutation>;
export type SetInstanceStateMutationOptions = Apollo.BaseMutationOptions<SetInstanceStateMutation, SetInstanceStateMutationVariables>;