import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { runPeriodType } from "../gameDataType";
import { format, getTime } from "date-fns";
import { Exact, GetGamesQuery, useDeleteRunPeriodMutation } from "../generated/graphql";
import { QueryResult } from "@apollo/client/react/types/types";
import { useSnackbar } from "notistack";
import EditSchedule from "./EditSchedule";

export default function RunPeriodDisplay({
  n,
  periodData,
  getGames,
}: {
  n: number;
  periodData: runPeriodType;
  getGames: QueryResult<GetGamesQuery, Exact<{ [key: string]: never }>>;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteRunPeriod] = useDeleteRunPeriodMutation({
    variables: { deleteRunPeriodId: periodData.id },
  });

  const [openEdit, setOpenEdit] = React.useState(false);

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid #eee",
        borderRadius: "10px",
        boxShadow: 1,
        marginY: "5px",
      }}
    >
      <Box
        sx={{
          paddingY: "2px",
          paddingX: "15px",
        }}
      >
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='column' sx={{ paddingTop: "5px" }}>
            <Stack direction='row' sx={{ marginRight: "15px" }}>
              <Typography variant='h6' sx={{ paddingRight: "10px", color: "success.main" }}>
                Start:
              </Typography>
              <Typography variant='h6'>
                {format(getTime(parseInt(periodData.start)), "dd/MM/yyyy hh:mm aaa")}
              </Typography>
            </Stack>
            <Divider />
            <Stack direction='row'>
              <Typography variant='h6' sx={{ paddingRight: "10px", color: "error.main" }}>
                End:
              </Typography>
              <Typography variant='h6' gutterBottom>
                {format(getTime(parseInt(periodData.end)), "dd/MM/yyyy hh:mm aaa")}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row'>
            <IconButton aria-label='edit' onClick={() => setOpenEdit(true)}>
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label='delete'
              onClick={async () => {
                const response = await deleteRunPeriod();
                if (response.data && response.data.deleteRunPeriod) {
                  enqueueSnackbar("Delete success!", { variant: "success" });
                  getGames.refetch();
                } else {
                  enqueueSnackbar("Error deleting", { variant: "error" });
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      {openEdit && (
        <EditSchedule setOpen={setOpenEdit} periodData={periodData} getGames={getGames} />
      )}
    </Box>
  );
}
