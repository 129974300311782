import { Box, AppBar, Toolbar, Button, Typography } from "@mui/material";
import { useLogoutMutation } from "../generated/graphql";
import { setAccessToken } from "../accessToken";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import React from "react";

export function MainAppBarUnmemo() {
  const [logout, { client }] = useLogoutMutation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' color='primary'>
        <Toolbar>
          <Typography
            variant='h6'
            component='div'
            sx={{ paddingRight: "5px" }}
            color='secondary.main'
          >
            Star Horizon
          </Typography>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            Game Manager
          </Typography>
          <Button
            variant='outlined'
            color='inherit'
            onClick={async () => {
              await logout();
              setAccessToken("");
              await client!.resetStore();
              navigate("/login");
              enqueueSnackbar("Logout successful", { variant: "success" });
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const MainAppBar = React.memo(MainAppBarUnmemo);

export default MainAppBar;
