import { decode } from "jsonwebtoken";

export interface userDetailsInterface {
  exp: number;
  iat: number;
  userId: number;
  permLevel: number;
}

let accessToken = "";

export const setAccessToken = (s: string) => {
  accessToken = s;
};

export const getAccessToken = () => {
  return accessToken;
};

export const getUserId = () => {
  try {
    return (decode(accessToken) as userDetailsInterface).userId;
  } catch {
    return false;
  }
};
