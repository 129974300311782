import React from "react";
import { BrowserRouter, Routes as RouterRoutes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";

export const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <div>
        <RouterRoutes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
        </RouterRoutes>
      </div>
    </BrowserRouter>
  );
};
