import { Backdrop, Box, Button, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/lab";
import React from "react";
import { compareAsc, add as dateAdd } from "date-fns";
import { useSnackbar } from "notistack";
import { Exact, GetGamesQuery, useEditRunPeriodMutation } from "../generated/graphql";
import { QueryResult } from "@apollo/client/react/types/types";
import { runPeriodType } from "../gameDataType";

export default function EditSchedule({
  setOpen,
  periodData,
  getGames,
}: {
  setOpen: Function;
  periodData: runPeriodType;
  getGames: QueryResult<GetGamesQuery, Exact<{ [key: string]: never }>>;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [addParams, setAddParams] = React.useState<{ start: Date | null; end: Date | null }>({
    start: new Date(parseInt(periodData.start)),
    end: new Date(parseInt(periodData.end)),
  });

  const [editRunPeriod] = useEditRunPeriodMutation();

  return (
    <>
      <Backdrop
        open={true}
        onClick={(event) => {
          if ((event.target as HTMLDivElement).classList.contains("backdropToClose"))
            setOpen(false);
        }}
        className='backdropToClose'
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "15px",
            boxShadow: 3,
            padding: "10px 20px 10px 20px",
          }}
        >
          <Stack sx={{ paddingTop: "5px" }}>
            <Typography variant='h4'>Edit Server Start/Stop</Typography>
            <Stack
              direction='column'
              sx={{ marginY: "20px", display: "block" }}
              spacing={3}
              justifyContent='center'
            >
              <DateTimePicker
                renderInput={(props) => <TextField {...props} sx={{ display: "block" }} />}
                label='Start Time'
                inputFormat='dd/MM/yyyy hh:mm aaa'
                value={addParams.start}
                onChange={(newValue) => {
                  if (newValue !== null) setAddParams({ ...addParams, start: newValue });
                  if (
                    addParams.end !== null &&
                    addParams.start !== null &&
                    compareAsc(addParams.end, addParams.start) === -1
                  )
                    addParams.end = dateAdd(addParams.start, { minutes: 1 });
                }}
                maxDateTime={addParams.end}
              />
              <DateTimePicker
                renderInput={(props) => <TextField {...props} sx={{ display: "block" }} />}
                label='End Time'
                inputFormat='dd/MM/yyyy hh:mm aaa'
                value={addParams.end}
                onChange={(newValue) => {
                  if (newValue !== null) setAddParams({ ...addParams, end: newValue });
                  if (
                    addParams.end !== null &&
                    addParams.start !== null &&
                    compareAsc(addParams.end, addParams.start) === -1
                  )
                    addParams.start = dateAdd(addParams.end, { minutes: -1 });
                }}
                minDateTime={addParams.start}
              />
              <Button
                variant='contained'
                sx={{ width: "min-content" }}
                onClick={async () => {
                  if (addParams.end === null || addParams.start === null) {
                    enqueueSnackbar("Please fill in the start and end times", { variant: "error" });
                  } else {
                    if (compareAsc(addParams.end, addParams.start) === 1) {
                      const response = await editRunPeriod({
                        variables: {
                          rpId: periodData.id,
                          startTime: addParams.start.getTime().toString(),
                          endTime: addParams.end.getTime().toString(),
                        },
                      });
                      if (response.data && response.data.editRunPeriod) {
                        enqueueSnackbar("Edit Success!", { variant: "success" });
                        setOpen(false);
                        getGames.refetch();
                      } else {
                        enqueueSnackbar("Error editing", { variant: "error" });
                      }
                    } else {
                      enqueueSnackbar("Ending time has to be after starting time!", {
                        variant: "error",
                      });
                    }
                  }
                }}
              >
                Schedule
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Backdrop>
    </>
  );
}
